import * as React from "react";
import { animated, useSpring } from '@react-spring/web'


export default function MovingBlockAnim({children, start, anim}:{children: React.ReactNode, start: boolean, anim: string}) {

  const [delayedStart, setDelayedStart] = React.useState(false);
  const [actualNode, setActualNode] = React.useState<any>();


  React.useEffect(() => {
  
    if (children !== actualNode || start) {
      setDelayedStart(false);
      const actualDelay = 800;
        const timeout = setTimeout(() => {
          setActualNode(children);
          setDelayedStart(true);
        }, actualDelay);

        return () => clearTimeout(timeout);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start,children]);


  let from, to;

if(anim === "up"){
        from = {y: -1000, opacity: 0 }
        to = delayedStart ? {y: 0, opacity: 1 } : from
}
if(anim === "down"){
          from = {y: 1000,opacity: 0 }
          to = delayedStart ? {y: 0, opacity: 1 } : from
    }
if(anim === "right"){
      from = {x: 1000, y: 0, opacity: 0}
      to = delayedStart ? {x: 0, y:0, opacity: 1 } : from
}
if(anim === "left"){
  from = {x: -1000, y: 0, opacity: 0}
  to = delayedStart ? {x: 0, y:0, opacity: 1 } : from
}
if(anim === "random"){
  from = {y: Math.random()*1000-500, x:Math.random()*1000-500,opacity: 0 }
  to = delayedStart ? {y: 0, x:0, opacity: 1 } : from
}


    const springs = useSpring({
    from: from,
    to: to,
    config: { mass: 2, tension: 150, friction: 35}
    });

     return(
      <>
          <animated.div style={{...springs}} className="h-full" >
            {actualNode}
          </animated.div>

     </>);

  };