import * as React from "react";
import { useSprings, animated } from "@react-spring/web";

export default function FallingLettersAnim({
  text,
  start,
  anim,
  speed = 30,
}: {
  text: string;
  start: boolean;
  anim: string;
  speed?: number;
}) {
  const [delayedStart, setDelayedStart] = React.useState(false);
  const [actualText, setActualText] = React.useState<string[]>("".split(""));

  const textArray = Array.from(text);

  React.useEffect(() => {
    if (textArray !== actualText || start) {
      setDelayedStart(false);
      const actualDelay = 800;
      const timeout = setTimeout(() => {
        setActualText(textArray);
        setDelayedStart(true);
      }, actualDelay);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [start, text]);

  let from, to;

  if (anim === "fade") {
    from = { opacity: 0 };
    to = delayedStart ? { opacity: 1 } : from;
  } else if (anim === "up") {
    from = { y: -1000, opacity: 0 };
    to = delayedStart ? { y: 0, opacity: 1 } : from;
  } else if (anim === "upleft") {
    from = { x: -1000, y: -100, opacity: 0 };
    to = delayedStart ? { x: 0, y: 0, opacity: 1 } : from;
  } else if (anim === "upright") {
    from = { x: 1000, y: -100, opacity: 0 };
    to = delayedStart ? { x: 0, y: 0, opacity: 1 } : from;
  } else if (anim === "downleft") {
    from = { x: -1000, y: 100, opacity: 0 };
    to = delayedStart ? { x: 0, y: 0, opacity: 1 } : from;
  } else if (anim === "downright") {
    from = { x: 1000, y: 100, opacity: 0 };
    to = delayedStart ? { x: 0, y: 0, opacity: 1 } : from;
  } else if (anim === "down") {
    from = { y: 1000, opacity: 0 };
    to = delayedStart ? { y: 0, opacity: 1 } : from;
  } else if (anim === "right") {
    from = { x: 1000, y: 0, opacity: 0 };
    to = delayedStart ? { x: 0, y: 0, opacity: 1 } : from;
  } else if (anim === "left") {
    from = { x: -1000, y: 0, opacity: 0 };
    to = delayedStart ? { x: 0, y: 0, opacity: 1 } : from;
  } else if (anim === "random") {
    from = {
      y: Math.random() * 1000 - 500,
      x: Math.random() * 1000 - 500,
      opacity: 0,
    };
    to = delayedStart ? { y: 0, x: 0, opacity: 1 } : from;
  }

  let transitionsConfig = actualText.map((t, i) => ({
    from: from,
    to: to,
    config: { mass: 2, tension: 150, friction: 35 },
    delay: i * speed,
  }));

  let springs = useSprings(actualText.length, transitionsConfig);

  return (
    <>
      {springs.map((style, index) => (
        <animated.span
          key={index}
          style={{ ...style, display: "inline-block" }}
        >
          {actualText[index] === " " ? <>&thinsp;</> : actualText[index]}
        </animated.span>
      ))}
    </>
  );
}

/*
  config: { mass: 4, tension: 200, friction: 30},
  transform: 'translate3d(0,-50px,0)' 
   transform: 'translate3d(0,0px,0)' 
  === ' ' ? <>&nbsp;</> : text[index]

      const from = {x: -1000 }
    const to = start ? {x: 0} : from

    const transitionsConfig = text.map((t, i) => ({
        from : from,
        to: to,
        
        delay: i*100,
      }));

       const [springs, api] = useSprings(text.length, transitionsConfig);

       return(
        <div><p>
       {springs.map((style, index) => (
            <animated.span key={index} style={style}>
              {text[index]}    
            </animated.span>
        ))}
       </p></div>);
  */
