import * as React from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./pages/home/index";
import Error from "./pages/error/index";
import NoPage from "./pages/nopage/index";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path ="/hu" element={<Navigate to="/" state='hu'/>}/>
        <Route path ="/en" element={<Navigate to="/" state='en'/>}/>
        <Route path="error" element={<Error />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
  </BrowserRouter>
  );
}

export default App;
