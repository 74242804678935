import React from "react";
import { Box } from "@mui/material";
import { IconButton, Link, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import MessengerIcon from "@mui/icons-material/Message"
import { LanguageContext } from ".";
import FallingTextAnim from "../../components/falling_text_anim"


export default function Contact() {
  const {lang} = React.useContext(LanguageContext);
  return (
    <Box
      id="Contact"
      sx={{
        mt: "10rem",
        scrollMarginTop: "5rem",
      }}
    >
      <Box className="flex flex-col justify-center items-center m-0 p-0">
        <Typography variant="h5" gutterBottom>
        <FallingTextAnim
          start={true}
          anim={"left"}
          speed={1}
        >
          {lang==='hu'
          ?<>Elérhetőségek</>
          :<>Contact Me</>
          }
        </FallingTextAnim>
        </Typography>
        <Typography variant="body1" paragraph>
        <FallingTextAnim
          start={true}
          anim={"right"}
          speed={1}
        >
          {lang==='hu'
          ?<>Keress meg az alábbi elérhetőségek egyikén:</>
          :<>Feel free to connect with me on social platforms or in email:</>
          }
        </FallingTextAnim>
        </Typography>
      </Box>

      <Box className="flex flex-row justify-center items-center m-0 p-0">
        <Link
          href="https://www.linkedin.com/in/kissbeni"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton color="primary">
            <LinkedInIcon fontSize="large" />
          </IconButton>
        </Link>
        <Link
          href="https://www.instagram.com/kissb3ni"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton color="primary">
            <InstagramIcon fontSize="large" />
          </IconButton>
        </Link>
        <Link
          href="mailto:info@kissbenedek.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton color="primary">
            <EmailIcon fontSize="large" />
          </IconButton>
        </Link>
        <Link
          href="http://m.me/OktatasKissBenedek"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton color="primary">
            <MessengerIcon fontSize="large" />
          </IconButton>
        </Link>

        

      </Box>
    </Box>
  );
}
