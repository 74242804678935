import * as React from "react";
import { Box, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import "./nopage.css"

export default function Index() {
    return (
        <Box className="items-center justify-center flex w-full h-full p-8 " >
            <SentimentVeryDissatisfiedIcon sx={{ fontSize: "12rem", color:"pink"}} />
            <Typography  variant="h4" component="h1" className="tracking-tight">
            <span className="font-extrabold text-8xl">404</span>
            <br/>
            Oh no!
            <br/> 
            Page not found
            </Typography>

        </Box>
    );
}