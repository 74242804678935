import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import picture from "../../png/benedek_opt.png";
import picture_low from "../../png/benedek_low.png";
import { Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import "./about.css"

import { LanguageContext } from ".";
import FallingTextAnim from "../../components/falling_text_anim"


export default function Home() {
  const [loading, setLoading] = React.useState(true);
  const imageLoaded = () => {
    setLoading(false);
  };

  const {lang} = React.useContext(LanguageContext);



  return (
    <Box
      id="Home"
      sx={{
        mb: 20,
        scrollMarginBottom: "20rem",
      }}
    >    

        <Grid
          container
          alignItems="stretch"
          spacing={{ xs: 2, md: 6 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
        <Grid
              item
              style={{ display: "flex", flexDirection: "column" }}
              xs={4}
              sm={2}
              md={4}
              key={1}
            >
    
      <Box className="m-auto max-w-sm aspect-square">
        <Box className="flex flex-col justify-center items-center m-0 p-0" 
              sx={{ display: loading ? "flex" : "none" }}>
                
          <CircularProgress color="inherit" className="absolute z-10" />
          <img
            src={picture_low}
            alt="Author's face low quality"
            style={{
              width: 384,
              height: "auto",
              zIndex: 0,
              
            }}
          />
      </Box>

      <Box
        className="flex flex-col justify-center items-center m-0 p-0" 
        sx={{ display: loading ? "none" : "flex" }}
      >
        <img
          src={picture}
          alt="Author's face"
          onLoad={imageLoaded}
          style={{ width: 384, height: "auto", zIndex: 10 }}
        />
      </Box>
      </Box>
      </Grid>


      <Grid
              item
              style={{ display: "flex", flexDirection: "column" }}
              xs={4}
              sm={6}
              md={8}
              key={2}
            >
      <div
        className="w-full m-auto text-center justify-center"
      >
        <Typography variant="h4" component="h3" className="tracking-tight">
        <FallingTextAnim
        start={true}
        anim={"left"}
        speed={1}
        >
          {lang==='hu'
          ? <>Szia, <span className="font-light">Kiss</span> Benedek vagyok</> 
          : <>Hi, I am Benedek <span className="font-light">Kiss</span></>}
        </FallingTextAnim>
        </Typography>
        <Divider />
        <br />
        
        <Typography variant="h5" component="h4" fontWeight="fontWeightMedium" className="tracking-wider">
        <FallingTextAnim
          start={true}
          anim={"right"}
          speed={1}>
          {lang==='hu'
          ?<>Mérnökinformatikus</>
          :<>Software Engineer</>
          }
        </FallingTextAnim>

        </Typography>
        <br />
        <Typography variant="h5" component="h4" fontWeight="fontWeightMedium" className="tracking-wider">
        <FallingTextAnim
          start={true}
          anim={"right"}
          speed={1}>
          {lang==='hu'
          ?<>Programozó, kávé rajongó</>
          :<>Developer, coffee fanatic</>
          }
        </FallingTextAnim>

      </Typography>
    
      </div>
      </Grid>
      </Grid>
      <div className="mt-4">
      <Typography variant="body1" className="tracking-wider break-keep text-justify">
        <FallingTextAnim
          start={true}
          anim={"left"}
          speed={1}
        >
          {lang==='hu'
          ?<>A tanítói pályafutásomat az egyetemen kezdtem és tanár családból érkezve hivatásomnak
          érzem, hogy a megszerzett tudást továbbadjam másoknak. Mérnökinformatikus mester diplomám van
          és jelenleg egy multinacionális telekommunikációs cégnél dolgozok, mint backend fejlesztő. 
          Arra törekszem, hogy tanulóim saját maguk tudják a feladatok megoldását kitalálni és az összefüggéseket
          megértsék az alapjaiktól kezdve. A tananyagot átültetem a gyakorlatba, hogy megmutassam, mennyire fontos
          a mindennapi életben. 7 év versenyszférában eltöltött idő segít abban, hogy gyakorlati tapasztalatokkal
          bővítsem a nálam tanulni vágyókat.</>
          :<>I'm an experienced instructor, fluent in both English and Hungarian. 
          I hold a master's degree in computer science engineering and I'm currently working for a multinational
          telecommunication company as a backend developer. 
          As an avid educator, I am passionate about sharing my knowledge and helping 
          others understand complex concepts, specializing in mathematics and programming. I strive for my students 
          to be able to solve problems on their own and to understand the connections from the basics.
          7 years in the competitive sector helps me to provide practical experience to those who wish to learn from me. </>
          }
        </FallingTextAnim>
        
      </Typography>
      </div>


    </Box>
    
  );
}
/*
<Grid container alignItems="center"  justifyContent="space-evenly" columnSpacing={{ xs: 1, md: 2 }}>

            <Grid item xs={12} md={4}>
              <Typography variant="h2" component="h1">
                I am<br/>Benedek<br/><span class="font-extrabold">Kiss</span>
              </Typography>
              <Divider sx={{ bgcolor: "white" }} />
              <Typography variant="h6">Software and Mechatronics Engineer by profession</Typography>
              <br/>
              <Typography variant="h6">Amid hiker, nature lover, microcontroller geek</Typography>
            </Grid>

            <Grid item xs={12} md={8} class="max-w-xl">
              <Grow in="True">
                <img src={picture} alt="picture"  />
              </Grow>
            </Grid>

          </Grid>
*/
