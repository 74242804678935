import React from "react";
import { useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import Paper from "@mui/material/Paper";


export default function Card({ children }: { children: React.ReactNode }) {
  const cardRef = useRef<HTMLDivElement>(null);
  const [elev, setElev] = useState(4);
  const config = {
    mass: 1,
    tension: 170,
    friction: 26,
    clamp: false,
    precision: 0.001,
    velocity: 0,
  };

  const [{ xys }, api] = useSpring(
    () => ({ 
      xys: [0, 0, 1], 
      config }),
    [config]
  );

  const onMouseLeave = () => {
    api.start({
      xys: [0, 0, 1],
    });
    setElev(3);
  };

  const onMouseMove = (e) => {
    const rect = cardRef?.current?.getBoundingClientRect();
    api.start({
      xys: calc(e.clientX, e.clientY, rect),
    });
    setElev(10);
  };

  return (
    <div ref={cardRef} style={{ height: "100%" }}>
      <animated.div
        style={{ height: "100%", transform: xys.to(trans) }}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <Paper elevation={elev} sx={{height:"100%", padding:'0.75rem'}}>{children}</Paper>
      </animated.div>
    </div>
  );
}

const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 10,
  (x - rect.left - rect.width / 2) / 10,
  1.05,
];

const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
