import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import { LanguageContext } from ".";
import Card from "../../components/card";

import CalculateIcon from "@mui/icons-material/Calculate";
import DevIcon from "@mui/icons-material/DeveloperMode";
import PLCIcon from "@mui/icons-material/LogoDev";

import MovingBlockAnim from "../../components/moving_block_anim";

const Content = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
  fontWeight: "300",
  lineHeight: "1.5rem",
  textAlign: "center",
}));

const Pricing = styled("p")({
  fontSize: 20,
  fontWeight: 400,
  margin: "12px",
});

const skills = [
  {
    logo: <CalculateIcon fontSize="large" />,
    titleHU: "Matematika oktatás",
    titleEN: "Mathematics tutoring",
    textHU: (
      <>
        Matematika korrepetálást, érettségi felkészítést vállalok gimnazista diákoknak, felnőtteknek.
        Tehetséggondozás általános iskolás felső tagozatos tanulóknak.
        Sárvár és környékén házhoz megyek!
      </>
    ),
    priceHU: (
      <>
        <Pricing>5000 Ft/60 perc</Pricing>
      </>
    ),
    textEN: (
      <>
        I tutor high school students in mathematics. I come to your home in
        Sárvár and its surroundings, Online tutoring is also possible, as well
        as personal tutoring currently available in Ikervár.
      </>
    ),
    priceEN: (
      <>
        <Pricing>15 eur/60 minutes</Pricing>
      </>
    ),
  },
  {
    logo: <DevIcon fontSize="large" />,
    titleHU: "Programozás oktatás",
    titleEN: "Programming tutoring",
    textHU: (
      <>
        Programozás oktatást, vizsgafelkészítést vállalok egyetemi szintig. Online,
        illetve Sárvár és környékén lehetőség van személyes oktatásra is. Az ár
        az oktatás tudásszintjének függvényében változik. (Python, C++, C#, VBS)
      </>
    ),
    priceHU: (
      <>
        <Pricing>5000-8000 Ft/60 perc</Pricing>
      </>
    ),
    textEN: (
      <>
        I teach programming from high school to university level. Primarily
        online, but also in person tutoring is also possible in and around
        Sárvár. The price varies depending on the level of knowledge. (Python,
        C++, C#, VBS)
      </>
    ),
    priceEN: (
      <>
        <Pricing>25-30 eur/60 minutes</Pricing>
      </>
    ),
  },
  {
    logo: <PLCIcon fontSize="large" />,
    titleHU: "PLC oktatás",
    titleEN: "PLC tutoring",
    textHU: (
      <>
        Magánoktatást vállalok PLC programozás, kamerás felismerés, robotika
        témakörből. Jártas vagyok különböző környezetekkel pl.: Omron,
        Mitsubishi, Allen-Bradley, Schneider. Cégeknek is! Csoportoknak kedvezmény!
      </>
    ),
    priceHU: (
      <>
        <Pricing>8000 Ft/60 perc</Pricing>
      </>
    ),
    textEN: (
      <>
        I teach PLC programming, mostly I'm experienced with Omron, Mitsubishi,
        Allen-Bradley, Schneider. I prefer to teach online, groups are also
        possible!
      </>
    ),
    priceEN: (
      <>
        <Pricing>25 eur/60 minutes</Pricing>
      </>
    ),
  },
];

export default function Services() {
  const { lang } = React.useContext(LanguageContext);
  return (
    <Box id="Services" sx={{ mb: "2rem", scrollMarginTop: "5rem" }}>
      <MovingBlockAnim start={true} anim={"left"}>
        <Box
          sx={{ mt: "1rem", mb: "2rem" }}
          className="w-full m-auto text-center justify-center"
        >
          <Typography variant="h5" component="h1">
            {lang === "hu" ? (
              <>Matematika - Programozás oktatás</>
            ) : (
              <>Mathematics - Programming tutoring</>
            )}
          </Typography>
        </Box>
      </MovingBlockAnim>
      <Box sx={{ display: "block" }}>
        <Grid
          container
          alignItems="stretch"
          spacing={{ xs: 3, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {skills.map((element, index) => (
            <Grid
              item
              style={{ display: "flex", flexDirection: "column" }}
              xs={4}
              sm={4}
              md={4}
              key={index}
            >
              <MovingBlockAnim start={true} anim={"right"}>
                <Card>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ display: "flex", margin: "0.5rem" }}>
                      <div>{element.logo}</div>
                      <div className="ml-1 my-auto">
                        <Typography
                          variant="p"
                          component="h2"
                          fontWeight="fontWeightBold"
                        >
                          {lang === "hu" ? element.titleHU : element.titleEN}
                        </Typography>
                      </div>
                    </Box>

                    <Content>
                      {lang === "hu" ? element.textHU : element.textEN}
                    </Content>
                    {/*
                    <Box sx={{ marginTop: "auto", padding: "0" }}>
                      <Content>
                        {lang === "hu" ? element.priceHU : element.priceEN}
                      </Content>
                    </Box>
                  */}
                  </Box>
                </Card>
              </MovingBlockAnim>
            </Grid>
          ))}
        </Grid>
      </Box>

      <MovingBlockAnim start={true} anim={"left"}>
        <Box
          sx={{ mt: "1rem", mb: "2rem" }}
          className="w-full m-auto text-center justify-center"
        >
          <Typography variant="body1" component="h3">
            {lang === "hu" ? (
              <>Az első, bemutatkozó óra árából 50% kedvezményt biztosítok! A személyes oktatás távolság függvényében árképző! </>
            ) : (
              <></>
            )}
          </Typography>
        </Box>
      </MovingBlockAnim>
    </Box>
  );
}
